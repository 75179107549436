@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
$base-grey: #fafafa;

body,
html {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  /* height: calc(var(--vh, 1vh) * 100); */
  background-color: $base-grey;
  display: flex;
  flex-direction: column;
}

code {
  font-family: "Roboto", sans-serif;
}

ul {
  padding: 0 !important;
}

.SocialMediaShareButton :hover {
  cursor: pointer;
}

#_hj_feedback_container {
  display: none;
}

.navbar-brand {
  font-family: "Oswald", sans-serif;
  margin-right: 0px;
}
