#main-content-col {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}

#video-content-col {
  display: flex;
  flex-direction: column;
  min-height: 50vh;
}
