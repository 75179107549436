// Wraps the entirety of the app
#main-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: 100%;
  min-width: 100%;
  flex: 1;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#content-container {
  flex: 1;
  display: flex;
}

.embed-holder {
  overflow: auto;
  padding-bottom: 56.25%;

  // The styling below displays the embed in the center
  // of the container but sometimes overflows
  // position: absolute;
  // left: 0; right: 0;
  // top: 50%;
  // margin-top: -28.1%;
  // padding-bottom: 56.25%;
  // height: 0;
  // overflow: hidden;
}

.embed-holder iframe {
  position: absolute;
  border: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.queue-closed-sign-up-form-container {
  display: flex;
  flex: 1;
  align-items: center;
}

.queue-closed-view {
  display: flex;
  flex: 1;
}

.queue-closed-div {
  display: flex;
  flex: 1;
}

#header {
  width: 100%;

  #logo {
    height: 100%;
    width: 50%;
    object-fit: cover;
  }

  #customerName {
    height: 100%;
    width: 100%;
  }

  .button {
    background-color: #1a1a1a;
    color: #fff;
  }

  .navbar-brand {
    // color: #343a40;
    font-weight: lighter;
    font-size: 1.5em;
    font-family: "Oswald", sans-serif;
    margin-right: 0px;
  }

  -webkit-box-shadow: 0 8px 6px -6px #999;
  -moz-box-shadow: 0 8px 6px -6px #999;
  box-shadow: 0 8px 6px -6px #999;
}

#footer {
  background: #343a40;
  color: #fff;
  width: 100%;
  font-size: small;
  font-family: "Roboto", sans-serif;
  text-align: center;
}

.accountContainer {
  min-height: 625px;
  min-width: 325px;
  max-width: 350px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 40px;
  border-radius: 4px;
  box-shadow: inset 0 1.75px 1.75px #ffffff;
  border: 2.5px solid #e3e3e3;
  background-color: #fff;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.mainContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  min-height: 90vh;
  display: inherit;
  flex: 1;
  overflow-y: auto;
}

#signInButton {
  width: 50%;
  margin: auto;
}
.backdropWhite {
  background-color: rgb(255, 255, 255);
}

.autoHeight {
  height: auto;
}

#termsAndConditionText {
  height: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(7px + 2vmin);
  font-weight: bold;
  color: white;
}

#formContent {
  padding-left: 5%;
  padding-right: 5%;
  height: 100%;
  width: 100%;
}

.modalContent {
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2%;
  padding-bottom: 2%;
  height: 100%;
  width: 100%;
  margin: 0 0 0 0;
}

.input {
  border-radius: 0;
}
.button {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: center;
}

.linkText {
  display: inline-block;
  text-align: right;
  vertical-align: middle;
  font-weight: bold;
  width: 100%;
  height: 100%;
  margin: 0 0 0 0;
  font-size: calc(1px + 2vmin);
}

#formContent #signUpCheckbox.checkboxFontSize {
  font-size: 2px;
}

#formContent .forgotPwdFontSize {
  font-size: calc(3px + 3vmin);
}

.modalContent #problemDetailsTextArea {
  height: 45vh;
  width: 100%;
  overflow-y: scroll;
  resize: none;
}

.modalContent .error {
  border-color: red;
}

.chatContent {
  overflow-y: scroll;
  flex: 1;
  overflow-wrap: break-word;
  padding-left: 1%;
  padding-right: 1%;
  vertical-align: middle;
  width: 100%;
}
.formContent {
  background-color: #f1f0f3;
}

.fullHeightAndWidth {
  height: 100%;
  width: 100%;
}

.companyFooter {
  background: rgb(16, 16, 16);
  color: rgb(157, 157, 157);
  position: absolute;
  bottom: 0;
  width: 100%;
}

.viewContainer {
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: hidden;
  display: flex;
  position: relative;
  /*padding-bottom: 24px; /* height of your footer */
}

.moreButton {
  height: 100%;
  padding: 0;
  width: 100%;
}

.message-row {
  min-height: 40px;
  height: 5vh;
}

%message-row {
  width: 100%;
  height: 100%;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  display: grid;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.more-menu-name {
  @extend %message-row;
  font-size: 1.25rem;
}

.priority0Message {
  @extend %message-row;
}

.priority1Message {
  background: orange;
  color: yellow;
  @extend %message-row;
}

.priority2Message {
  background: yellow;
  color: black;
  @extend %message-row;
}

.topicRadio {
  width: 100%;
}

.topicRadio > div {
  text-align: left;
  width: 100%;
}

.topicRadio > div > .form-control {
  display: inline-block !important;
  width: 70% !important;
}

.topicRadio > div > .btn-primary {
  width: 20% !important;
}

input[type="radio"] {
  font-size: 24px;
  border-radius: 0;
}

.snapshotView {
  display: block;
  margin: 0 auto 0 auto;
  max-width: 80%;
  min-width: 80%;
  height: auto;
}

#videoCall {
  background-color: #f1f0f3;
}

.videoEmbed {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.videoPlayer {
  height: 100%;
  max-height: 100%;
  vertical-align: middle;
  width: 100%;
}

.iconTextSize {
  font-size: 15px;
}

.menuButtonTextSize {
  font-size: 2.5vh;
}

svg.social-icon.social-icon--facebook {
  border-radius: 50%;
}

svg.social-icon.social-icon--twitter {
  border-radius: 50%;
}

.customPromptEntry:hover {
  cursor: pointer;
}

.intl-tel-input {
  width: 100%;
}

#toastAlert {
  width: 100%;
}
